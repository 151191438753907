<template>
<div class="page-box page-box-setting" v-loading="pageLoading">
    <lz-page-panel :title="$t('系统设置')">
      <div class="setting-body">
        <div v-for="(item, index) in settingItems" :key="item.id">
          <div v-if="item.isShow" v-loading="item.loading" class="setting-item mr-20 pv-20 pl-20">
            <div class="display__flex justify_content__space_between">
              <p class="item-title">{{item.title}}</p>
              <el-switch v-model="item.isOpen" :width="36" @change="handleSwitchChange(index)"></el-switch>
            </div>
            <p class="item-content mt-15">{{item.content}}</p>
          </div>
        </div>
      </div>
    </lz-page-panel>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapGetters } from 'vuex';
import serviceAPI from './api';

/**
 * 设置页面
 * @module @/view/setting
 */
export default {
  name: "Setting",
  data() {
    return {
      pageLoading: false,

      settingItems: [
      { id: 1, title: intl.$t("自动生成密码"), content: intl.$t("开启后，新增成员时的密码输入框自动填充一个8位密码"), isOpen: false, isShow: false, loading: false },
      { id: 2, title: intl.$t("自动生成账号"), content: intl.$t("开启后，创建账号时自动使用输入的姓名拼音进行填充"), isOpen: false, isShow: false, loading: false },
      { id: 3, title: intl.$t("账号密码创建通知"), content: intl.$t("开启后，新增成员/重置密码时自动发送账密短信或邮件，用户必须填写手机号或邮箱地址，否则发送失败"), isOpen: false, isShow: false, loading: false }]

    };
  },
  computed: {
    ...mapGetters([
    'pageButtonPermission']
    )
  },
  created() {
    this.getValue();
  },
  methods: {
    /**
     * 查询系统设置
     */
    getValue() {
      this.pageLoading = true;
      serviceAPI.getValue().then((res) => {
        this.pageLoading = false;
        if (res.code == 200 && res.data) {
          this.settingItems[0].isOpen = res.data.pwdAutoCreate == 1 ? true : false;
          this.settingItems[0].isShow = this.pageButtonPermission('hrms/setting', 'pwdAutoCreate') ? true : false;
          this.settingItems[1].isOpen = res.data.accountAutoFill == 1 ? true : false;
          this.settingItems[1].isShow = this.pageButtonPermission('hrms/setting', 'accountAutoFill') ? true : false;
          this.settingItems[2].isOpen = res.data.pwdMsgAutoSend == 1 ? true : false;
          this.settingItems[2].isShow = this.pageButtonPermission('hrms/setting', 'pwdMsgAutoSend') ? true : false;
        } else {
          this.$notice.error(res.message);
        }
      }).catch((exec) => {
        this.pageLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get settings value exception: ', exec.message);
      });
    },

    /**
     * 设置项状态修改事件
     * @param { Number } index 修改项数据源索引
     */
    handleSwitchChange(index) {
      let data = this.settingItems[index];
      const params = {
        type: data.id,
        value: data.isOpen ? 1 : 0
      };
      data.loading = true;
      serviceAPI.saveValue(params).then((res) => {
        data.loading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("设置成功！"));
        } else {
          this.$notice.error(res.message);
        }
      }).catch((exec) => {
        data.loading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Setting switch changed exception: ', exec.message);
      });
    }
  }
};
</script>

<style scoped>
.page-box-setting {
    position: relative;
  }

  .page-box-setting .lz-page-panel {
    background-color: #EFF4FF;
  }

  .setting-body {
    display: flex;
  }

  .setting-item {
    width: 280px;
    height: 142px;
    background-color: #FFF;
    border: 1px solid #E4E8F6;
    border-radius: 2px;
    margin-right: 12px;
    padding-right: 30px;
  }

  .item-title {
    font-weight: bold;
  }

  .item-content {
    font-size: 12px;
    color: #596176;
  }
</style>
